<template>
  <div style="padding-bottom:44px">
    <van-field label="序列号" v-model="rhd001_Step2.serialNumber">
      <template #right-icon>
        <van-tag type="warning">{{jieMa}}</van-tag>
      </template>
    </van-field>
    <van-cell title="上电检测3V">
      <template #right-icon>
        <van-checkbox v-model="rhd001_Step2.shangDianJianCe3V" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="液晶显示测试">
      <template #right-icon>
        <van-checkbox v-model="rhd001_Step2.yeJingXianShiCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="按键测试">
      <template #right-icon>
        <van-checkbox v-model="rhd001_Step2.anJianCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="蜂鸣器测试">
      <template #right-icon>
        <van-checkbox v-model="rhd001_Step2.fengMingQiCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="对光检测">
      <template #right-icon>
        <van-checkbox v-model="rhd001_Step2.duiGuangCeShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="传感器标定">
      <template #right-icon>
        <van-checkbox v-model="rhd001_Step2.chuanGanQiBiaoDing" shape="square"></van-checkbox>
      </template>
    </van-cell>
     <van-cell title="温度检定">
      <template #right-icon>
        <van-checkbox v-model="rhd001_Step2.wenDingJianDing" shape="square"></van-checkbox>
      </template>
    </van-cell>
     <van-cell title="加热功能检测">
      <template #right-icon>
        <van-checkbox v-model="rhd001_Step2.jiaReGongNengJianCe" shape="square"></van-checkbox>
      </template>
    </van-cell>
     <van-cell title="升温速率检测">
      <template #right-icon>
        <van-checkbox v-model="rhd001_Step2.shengWenSuLvJianCe" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-field label="备注" v-model="rhd001_Step2.remark"> </van-field>
    <el-button type="primary" class="footer1" icon="el-icon-view" circle @click="handlePicClick"></el-button>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step2" round block :disabled="$parent.rhd001_SignNumber.status!=0" @click="handleSave">保存</van-button>
      <van-button type="danger" v-if="$parent.rhd001_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.rhd001_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>

    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  props: ["signNumber"],
  data() {
    return {
      rhd001_Step2: {},
      jieMa: "",
    };
  },
  methods: {
    handlePicClick() {
      ImagePreview(["./images/RHD001_Produce.jpg"]);
    },
    getRHD001_Step2() {
      let that = this;
      that.axios
        .post("/RHD001/GetRHD001_Step2", { onlyText: that.signNumber })
        .then(function (response) {
          that.rhd001_Step2 = response.data.data;
        });
    },
    handleSave() {
      let that = this;
      that.axios
        .all([that.save_RHD001_Step2(), that.registerSerialNumber()])
        .then(
          that.axios.spread(function (response1, response2) {
            that.rhd001_Step2 = response1.data.data;
            if (response1.data.code == 201) {
              that.$notify({ type: "warning", message: response1.data.msg });
            } else if (response2.data.code == 201) {
              that.$notify({ type: "warning", message: response2.data.msg });
            } else {
              that.$notify({ type: "success", message: response1.data.msg });
            }
            if (response2.data.code == 101) {
              that.jieMa = response2.data.msg;
            }
          })
        );
    },
    save_RHD001_Step2() {
      let that = this;
      return that.axios.post("RHD001/UpdateRHD001_Step2", that.rhd001_Step2);
    },
    registerSerialNumber() {
      let that = this;
      return that.axios.post("Base_Device/Register", {
        productName: "新版软化点",
        productId: 1109,
        serialNumber: that.rhd001_Step2.serialNumber,
        deviceType: "RHD001",
        companyName: "",
        ifPrint: false,
      });
    },
  },
  mounted() {
    let that = this;
    that.getRHD001_Step2();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.footer1 {
  position: fixed;
  left: 10px;
  bottom: 50px;
}
</style>